'use client';

import type { DehydratedState } from '@tanstack/react-query';
import React from 'react';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink, loggerLink } from '@trpc/client';
import superjson from 'superjson';
import type { PageProps } from '@monorepo/interfaces';
import { getUrl } from '@monorepo/server/src/config/shared';
import { trpc } from '../trpc/react';
import { unauthorizedLink } from './unauthorizedLink';
const makeQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
      }
    }
  });
};
let clientQueryClient: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === 'undefined') return makeQueryClient();
  if (!clientQueryClient) clientQueryClient = makeQueryClient();
  return clientQueryClient;
};
export function TRPCProvider({
  children,
  basePath = '',
  trpcState,
  cookies
}: React.PropsWithChildren<PageProps>) {
  const queryClient = getQueryClient();
  const [trpcClient] = React.useState(trpc.createClient({
    links: [unauthorizedLink, httpLink({
      url: getUrl(basePath),
      transformer: superjson,
      headers() {
        return {
          Cookie: cookies
        };
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include'
        });
      }
    }), loggerLink({
      enabled: () => process.env.NODE_ENV === 'development'
    })]
  }));
  const hydratedState: DehydratedState | undefined = React.useMemo(() => {
    if (!trpcState) {
      return trpcState;
    }
    return superjson.deserialize(trpcState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trpcState, trpcClient]);
  return <trpc.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-component="TRPCProvider" data-sentry-source-file="react.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="react.tsx">
        <HydrationBoundary state={hydratedState} data-sentry-element="HydrationBoundary" data-sentry-source-file="react.tsx">{children}</HydrationBoundary>
      </QueryClientProvider>
    </trpc.Provider>;
}