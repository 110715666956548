export enum CookiesKeys {
  TAX_ID = '__OISA-AU-TI',
  AUTH = '__OISA-SH-AT',
  REALM = '__OISA-SH-TR',
  REFRESH = '__OISA-SH-RT',
  PERSON_ID = '__OISA-IR-PI',
  LAST_PATH = '__OISA-MI-LP',
  SCHOOL_IDS = '__OISA-IR-SI',
  APP_DISABLED = '__OISA-MI-AD',
  GLOBAL_TOKEN = '__OISA-SH-GT'
}

export enum SessionStorageKeys {
  VIEWED_FIDC_MODAL = '__OISA-FC-VM',
  UPDATED_ENROLLMENT_STATUS = '__OISA-MA-UE',
  IMPERSONATE_STORE = '__OISA-NEG-IS',
}

export enum LocalStorageKeys {
  PWA_INSTALLED = '__OISA-MI-PWA',
}

export type CheckTaxIdOutputFlow =
  | 'first_access'
  | 'first_access_with_email'
  | 'first_access_with_phone'
  | 'password'
  | 'create_password'
  | 'update_contact'
  | 'no_access'
  | 'no_sorting_hat_user';

export type CheckUserByTaxIdResponse = {
  flow: CheckTaxIdOutputFlow;
};
