import { useEffect, useState } from 'react';

import { useNativeFeatures } from './use-native-features';

export const useIsMobileFlag = () => {
  const { device } = useNativeFeatures();
  const [platform, setPlatform] = useState<string | undefined>();

  useEffect(() => {
    const getPlatform = async () => {
      setPlatform((await device?.Device.getInfo())?.platform);
    };

    void getPlatform();
  }, [device?.Device]);

  return platform === 'ios' || platform === 'android';
};
