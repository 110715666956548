'use client';

import type { PropsWithChildren } from 'react';
import { useIsMobileFlag, useSafeAreaInsets } from '@monorepo/unified-shell';
export function MobileSafeArea({
  children
}: PropsWithChildren) {
  const {
    top,
    bottom
  } = useSafeAreaInsets();
  const isMobile = useIsMobileFlag();
  if (!isMobile) return children;
  return <div style={{
    paddingTop: top,
    paddingBottom: bottom
  }} data-sentry-component="MobileSafeArea" data-sentry-source-file="index.tsx">{children}</div>;
}