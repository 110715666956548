import type { Agreement, Negotiation } from './agreements';

export type GetInstallmentsResponseV2 = {
  data: InstallmentResponseV2[] | null;
};

export type GetInstallmentV2 = {
  data: InstallmentV2[];
};

export type GetInstallmentV2RouteResponse = {
  data: InstallmentV2[];
  message: string;
};

export enum InstallmentStatuses {
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  DUE_TODAY = 'DUE_TODAY',
  AWAITING = 'AWAITING',
}

export type Product = { id: string; name: string };

export type InstallmentResponseV2 = {
  urn: string;
  type: InstallmentTypeV2;
  status: InstallmentStatus;
  products: Product[];
  description?: string;
  order_reference: string;
  receivable_type: ReceivableTypeV2;
  due_date?: string;
  paid_date?: string;
  paid_amount?: number;
  paid_payment_method?: PaymentMethods;
  enabled_payment_methods?: PaymentMethods[];
  original_amount: number;
  due_amount: number;
  base_amount: number;
  current_amount: number;
  current_discount: number;
  current_early_payment_discount: number;
  current_due_payment_discount: number;
  current_perpetual_discount: number;
  current_interest: number;
  current_fine: number;
  lost_early_payment_discount: number;
  lost_due_payment_discount: number;
  exempt_interest: number;
  exempt_fine: number;
  overdue: boolean;
  discounts?: Discount[];
  invoice: InvoiceResponseV2;
  contracts: ContractV2[];
  student: InstallmentStudent;
  guardian: InstallmentGuardianV2;
  in_negotiations?: Negotiation[];
  origin_negotiation?: Negotiation;
  is_from_credit_card_flow?: boolean;
  credit_card_fee?: number;
  is_hybrid_model_teaching_material?: boolean;
};

export type InstallmentV2 = {
  id: string;
  type: InstallmentTypeV2;
  status: InstallmentStatus;
  product: string;
  product_id?: string;
  description?: string;
  order_reference: string;
  receivable_type: ReceivableTypeV2;
  due_date?: string;
  paid_date?: string;
  paid_amount?: number;
  paid_payment_method?: PaymentMethods;
  enabled_payments_methods?: PaymentMethods[];
  original_amount: number;
  due_amount: number;
  base_amount: number;
  current_amount: number;
  current_discount: number;
  current_early_payment_discount: number;
  current_due_payment_discount: number;
  current_perpetual_discount: number;
  current_interest: number;
  current_fine: number;
  lost_early_payment_discount: number;
  lost_due_payment_discount: number;
  exempt_interest?: number;
  exempt_fine?: number;
  overdue: boolean;
  backoffice_installment_id?: string;
  expired?: boolean;
  early_payment_discounts: Discount[];
  due_payment_discounts: Discount[];
  perpetual_discounts: Discount[];
  invoice: InvoiceV2;
  contract?: ContractV2;
  student: InstallmentStudent;
  guardian: InstallmentGuardianV2;
  in_negotiations?: Negotiation[];
  origin_negotiation?: Negotiation;
  down_payment_waiting_payment?: boolean;
  is_from_credit_card_flow: boolean;
  credit_card_fee: number;
  is_hybrid_model_teaching_material?: boolean;
};

export type InstallmentGuardianV2 = Pick<
  InstallmentGuardian,
  'name' | 'email' | 'tax_id' | 'source_id'
>;

export type InvoiceV2 = {
  id: string;
  digitable_line?: string;
  pix_code?: string;
  payment_link?: string;
  payment_instrument_status: 'GENERATED' | 'PENDING' | 'WITH_ERROR';
  payment_instrument_additional_Info?: string;
  is_expired?: boolean;
};

export type InvoiceResponseV2 = Omit<InvoiceV2, 'digitable_line'> & {
  digital_line?: string;
};

export type InstallmentStatus =
  | 'OPEN'
  | 'RENEGOTIATED'
  | 'OVERDUE'
  | 'PAID'
  | 'CANCELED'
  | 'AGGLUTINATED'
  | 'CHECKOUTED'
  | 'AWAITING'
  | 'BROKEN_PROMISE';

export type NegotiationStatus =
  | 'PROMISE'
  | 'BROKEN_PROMISE'
  | 'BROKEN_DEAL'
  | 'PAID_DEAL'
  | 'CANCELED'
  | 'DEAL';

export enum InstallmentStatusTab {
  OPEN = 0,
  PAID = 1,
}

export enum InstallmentTypeLabel {
  ENROLLMENT = 'Matrícula',
  TUITION = 'Mensalidade',
}

export enum InstallmentOverviewStatus {
  ALL_PAID = 'ALL_PAID',
  NONE_PENDING = 'NONE_PENDING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export type PaymentMethods =
  | 'CREDIT_CARD'
  | 'PIX'
  | 'BANK_SLIP'
  | 'SCHOOL_PORTFOLIO_REPURCHASE';

export type ChosenPaymentPlanMethods = 'CREDIT_CARD' | 'BANK_SLIP' | 'HYBRID';

export enum InvoiceStatuses {
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  RENEGOTIATED = 'RENEGOTIATED',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export type InvoiceStatus =
  | 'OPEN'
  | 'OVERDUE'
  | 'RENEGOTIATED'
  | 'PAID'
  | 'CANCELED';

export type InstallmentType = 'TUITION' | 'ENROLLMENT';

export type ReceivableTypeV2 = 'TUITION' | 'ENROLLMENT' | 'ISAAC_PAY';

export type InstallmentTypeV2 = 'RECEIVABLE' | 'DOWN_PAYMENT';

export type DiscountType =
  | 'EARLY_PAYMENT'
  | 'DUE_PAYMENT'
  | 'PERPETUAL'
  | 'EARLY_PAYMENT_DISCOUNT';

export type Discount = {
  type?: DiscountType;
  days_before_due_date?: number;
  amount: number;
  description?: string;
  limit_date?: string;
};

export type InstallmentHelpData = {
  id: string;
  referenceMonth: number;
  currentAmount?: number;
  product: string;
  dueDate?: string;
  paidDate?: string;
  status: InstallmentStatus;
  overdue: boolean;
};

export type InstallmentData = {
  id: string;
  installmentNumber: string;
  referenceYear?: string;
  amount: number;
  total: number;
  discount: number;
  dueDiscount: number;
  earlyDiscounts: {
    amount: number;
    date?: string;
    lost: boolean;
  }[];
  isPaid: boolean;
  agreementDate?: string;
  dueDate?: string;
  paidDate?: string;
  interest: number;
  fine: number;
  month: number;
  product: string;
  product_id?: string;
  school?: SchoolV2;
  contractId?: string;
  student: {
    id?: string;
    name: string;
  };
  pixcode?: string | null;
  barcode?: string | null;
  payment_link?: string | null;
  payment_methods?: PaymentMethods[] | null;
  dashboardTab?: number;
  overdue: boolean;
  isOverdue: boolean;
  type: InstallmentTypeV2;
  receivableType: ReceivableTypeV2;
  expired?: boolean;
  remainingDays: number;
  status: InstallmentStatus;
  agreementTag?: string | null;
  agreementNumber?: number | null;
  agreementSize: number;
  downPaymentWaitingPayment?: boolean;
  isFromCreditCardFlow: boolean;
  creditCardFee: number;
  isHybridModelTeachingMaterial?: boolean;
};

export type InvoiceData = {
  id: string;
  dueDate?: string;
  link?: string;
  agreement?: Agreement;
};

export type Invoice = {
  id: string;
  due_date?: string;
  digitable_line?: string;
  pix_code?: string;
  payment_link?: string;
  bad_credit?: boolean;
  status?: InvoiceStatus;
  email_sent?: string;
};

export type SchoolV2 = {
  id?: string;
  name: string;
  slug?: string;
};

export type ContractV2 = {
  id: string;
  reference_year: string;
  number_of_installments: number;
  school: SchoolV2;
  erp: string;
};

export type Contract = {
  id: string;
  reference_year: string;
  number_of_installments: number;
  school_name: string;
  erp: string;
};

export type InstallmentStudent = {
  name: string;
  source_id: string;
  id?: string;
};

export type InstallmentGuardian = {
  id?: string;
  name: string;
  email: string;
  phone_number: string;
  tax_id: string;
  source_id: string;
};

export type InstallmentGuardianHelpData = Pick<
  InstallmentGuardian,
  'name' | 'tax_id'
>;

export type InstallmentFilters = {
  status?: InstallmentStatus[];
  type?: InstallmentTypeV2;
  receivable_type?: ReceivableTypeV2;
  urn?: string;
  guardian_ids?: string;
  tax_id?: string;
  student_id?: string;
};

export type GetInstallmentsInput = {
  correlationId: string;
  filters: InstallmentFilters;
};
